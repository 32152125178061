import axios from '@/libs/axios'

class Game {
  static list(params) {
    return axios.post('user/games', params)
  }

  static reportWinLose(params) {
    return axios.post('user/games/win-lose', params)
  }

  static transactionList(params) {
    return axios.post('user/games/transactions', params)
  }

  static auditList(params) {
    return axios.post('user/games/transactions/audit', params)
  }

  static async downloadTransactionList(params) {
    const URLparams = new URLSearchParams(Object.entries(params))
    window.open(`${process.env.VUE_APP_API_BASE_URL}user/games/transactions/export?${URLparams}`, '_blank')
  }

  static async downloadGameHistoryList(params) {
    const URLparams = new URLSearchParams(Object.entries(params))
    window.open(`${process.env.VUE_APP_API_BASE_URL}user/games/export?${URLparams}`, '_blank')
  }

  static async downloadWinLoseList(params) {
    const URLparams = new URLSearchParams(Object.entries(params))
    window.open(`${process.env.VUE_APP_API_BASE_URL}user/games/win-lose/export?${URLparams}`, '_blank')
  }

  static settle(params) {
    return axios.post('user/games/settle', params)
  }
}

export default Game
