<template>
  <div>
    <!-- Filter -->
    <b-card
      footer-tag="footer"
      class="mb-1 card-filter-container"
    >
      <b-row>
        <b-col
          sm="12"
          md="6"
          lg="3"
          class="card-filter-item"
        >
          <b-input-group class="mb-1">
            <b-input-group-prepend is-text>
              <span
                style="min-width: 95px;"
                v-text="$t('date_from')"
              />
            </b-input-group-prepend>
            <b-form-datepicker
              v-model="filter.dateFrom"
              :max="filter.dateTo"
            />
          </b-input-group>
          <b-input-group class="mb-1-sm">
            <b-input-group-prepend is-text>
              <span
                style="min-width: 95px;"
                v-text="$t('time_from')"
              />
            </b-input-group-prepend>
            <b-form-timepicker
              v-model="filter.timeFrom"
              show-seconds
              locale="en"
            />
          </b-input-group>
        </b-col>
        <b-col
          sm="12"
          md="6"
          lg="3"
          class="card-filter-item"
        >
          <b-input-group class="mb-1">
            <b-input-group-prepend is-text>
              <span
                style="min-width: 95px;"
                v-text="$t('date_to')"
              />
            </b-input-group-prepend>
            <b-form-datepicker
              v-model="filter.dateTo"
              :min="filter.dateFrom"
            />
          </b-input-group>
          <b-input-group class="mb-1-sm mb-1-md">
            <b-input-group-prepend is-text>
              <span
                style="min-width: 95px;"
                v-text="$t('time_to')"
              />
            </b-input-group-prepend>
            <b-form-timepicker
              v-model="filter.timeTo"
              show-seconds
              locale="en"
            />
          </b-input-group>
        </b-col>
        <b-col
          sm="12"
          md="6"
          lg="3"
          class="card-filter-item"
        >
          <b-input-group class="mb-1">
            <b-input-group-prepend is-text>
              <span
                style="min-width: 95px;"
                v-text="$t('account')"
              />
            </b-input-group-prepend>
            <b-form-input
              v-model="filter.account"
              autocomplete="off"
            />
          </b-input-group>
          <b-input-group class="mb-1-sm mb-1-md-remove">
            <b-input-group-prepend is-text>
              <span
                style="min-width: 95px;"
                v-text="$t('shoe_id')"
              />
            </b-input-group-prepend>
            <b-form-input
              v-model="filter.shoeId"
              autocomplete="off"
            />
          </b-input-group>
        </b-col>
        <b-col
          sm="12"
          md="6"
          lg="3"
          class="card-filter-item"
        >
          <b-input-group>
            <b-input-group-prepend is-text>
              <span
                style="min-width: 95px;"
                v-text="$t('vip_room')"
              />
            </b-input-group-prepend>
            <b-form-select
              v-model="filter.vipRoom"
              :options="vipRoomOptions"
            />
          </b-input-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          sm="12"
          md="12"
          lg="12"
          class="card-filter-item mt-1"
        >
          <b-button
            variant="primary float-right"
            @click="loadData"
            v-text="$t('search')"
          />
        </b-col>
      </b-row>
    </b-card>
    <!-- Table Container Card -->
    <b-overlay
      :show="loading"
      opacity="0.80"
      rounded="sm"
    >
      <b-card
        no-body
        class="mb-0"
      >
        <b-table
          responsive
          show-empty
          :items="items"
          :fields="fields"
          class="mb-0 game-history-table"
        >
          <template #head(account)>
            <span v-text="$t('account')" />
          </template>
          <template #head(begin_time)>
            <span v-text="$t('begin_time')" />
          </template>
          <template #head(vip_room)>
            <span v-text="$t('vip_room')" />
          </template>
          <template #head(table_id)>
            <span v-text="$t('table_id')" />
          </template>
          <template #head(shoe_id)>
            <span v-text="$t('shoe_id')" />
          </template>
          <template #head(game_id)>
            <span v-text="$t('game_id')" />
          </template>
          <template #head(bet_type)>
            <span v-text="$t('bet_type')" />
          </template>
          <template #head(amount)>
            <span v-text="$t('amount')" />
          </template>
          <template #head(player)>
            <span v-text="$t('player')" />
          </template>
          <template #head(banker)>
            <span v-text="$t('banker')" />
          </template>
          <template #head(result)>
            <span v-text="$t('result')" />
          </template>
          <template #head(win_lose)>
            <span v-text="$t('win_lose')" />
          </template>
          <template #head(settle_status)>
            <span v-text="$t('settle_status')" />
          </template>
          <template #head(end_time)>
            <span v-text="$t('end_time')" />
          </template>
          <template #head(rolling)>
            <span v-text="$t('rolling')" />
          </template>
          <template #head(action)>
            <span v-text="$t('action')" />
          </template>
          <!-- Column: account -->
          <template #cell(account)="data">
            <a
              href="#"
              class="align-middle ml-50"
              @click="filterByAccount(data.item.account)"
              v-text="data.item.account"
            />
          </template>
          <!-- Column: bet_type -->
          <template #cell(bet_type)="data">
            <span
              :class="`game-history-${data.item.bet_type}-type`"
              v-text="data.item.bet_type.toUpperCase()"
            />
          </template>
          <!-- Column: amount -->
          <template #cell(amount)="data">
            <span
              class="align-middle ml-50"
              v-text="Number(data.item.amount).toLocaleString()"
            />
          </template>
          <!-- Column: win_lose -->
          <template #cell(win_lose)="data">
            <span
              :class="`game-result-${data.item.game_result}`"
              v-text="Number(data.item.win_lose).toLocaleString()"
            />
          </template>
          <!-- Column: player -->
          <template #cell(player)="data">
            <div
              v-if="data.item.player_game_card.length > 0"
              class="text-center card-result-container"
              :class="(data.item.card_result !== 'player') ? 'lose-card' : ''"
            >
              <span
                v-for="(card, index) in data.item.player_game_card"
                :key="index"
                class="card-display"
              >
                <img
                  :src="card.icon"
                >
                <span v-text="card.display" />
              </span>
            </div>
          </template>
          <!-- Column: banker -->
          <template #cell(banker)="data">
            <div
              v-if="data.item.banker_game_card.length > 0"
              class="text-center"
            >
              <span
                v-for="(card, index) in data.item.banker_game_card"
                :key="index"
                class="card-display"
              >
                <img
                  :src="card.icon"
                >
                <span v-text="card.display" />
              </span>
            </div>
          </template>
          <!-- Column: result -->
          <template #cell(result)="data">
            <span
              :class="`card-result-${data.item.card_result} result-settle-status-${data.item.settle_status.toLowerCase()}`"
              v-text="data.item.result"
            />
          </template>
          <!-- Column: shoe_id -->
          <template #cell(shoe_id)="data">
            <span class="game-table-id">{{ data.item.game_table_id }}</span>
            <span class="game-shoe-id">{{ data.item.game_shoe_id }}</span>
            <span class="game-round-id">{{ data.item.game_round_id }}</span>
          </template>
          <!-- Column: Rolling -->
          <template #cell(rolling)="data">
            <span
              v-text="Number(data.item.rolling).toLocaleString()"
            />
          </template>
          <!-- Column: Settle Status -->
          <template #cell(settle_status)="data">
            <span
              :class="`settle-status-${data.item.settle_status.toLowerCase()}`"
              v-text="data.item.settle_status"
            />
          </template>
          <!-- Column: Action -->
          <template #cell(action)="data">
            <b-button
              v-if="data.item.can_settle === true"
              variant="outline-primary"
              size="sm"
              @click="showSettle(data.item)"
              v-text="$t('settle')"
            />
          </template>

        </b-table>
        <template slot="footer">
          <b-pagination
            v-model="filter.page"
            :per-page="perPage"
            hide-goto-end-buttons
            :total-rows="totalPage"
            align="center"
            @change="loadData"
          />
        </template>
      </b-card>
    </b-overlay>

    <!-- Settle Modal -->
    <b-modal
      ref="settle-modal"
      size="lg"
      :title="$t('settle')"
      centered
      no-close-on-backdrop
      hide-footer
    >
      <b-form
        class="settle-modal"
        @submit.stop.prevent="settleHandleSubmit"
      >
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          :label="$t('player_cards')"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="12"
              class="text-left"
            >
              <span
                v-for="(item, index) in settleFormData.cards.player"
                :key="index"
                class="card-display clickable"
                @click="showCardSelection('player', index)"
              >
                <img
                  v-if="item.card !== null"
                  :src="cardTypes[item.card.substring(0, 1)]"
                >
                <span v-else>?</span>
                <span
                  v-if="item.card !== null"
                  v-text="(item.card.substring(1) === 'X') ? '10' : item.card.substring(1)"
                />
              </span>
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          :label="$t('banker_cards')"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="12"
              class="text-left"
            >
              <span
                v-for="(item, index) in settleFormData.cards.banker"
                :key="index"
                class="card-display clickable"
                @click="showCardSelection('banker', index)"
              >
                <img
                  v-if="item.card !== null"
                  :src="cardTypes[item.card.substring(0, 1)]"
                >
                <span v-else>?</span>
                <span
                  v-if="item.card !== null"
                  v-text="(item.card.substring(1) === 'X') ? '10' : item.card.substring(1)"
                />
              </span>
            </b-col>
          </b-row>
        </b-form-group>
      </b-form>
      <footer
        class="modal-footer"
        style="margin: 0px -1.4rem"
      >
        <b-row style="width: 100%">
          <b-col
            cols="12"
            md="12"
            xl="12"
            style="text-align: center"
            class="mt-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              class="mr-1"
              @click="settleHandleCancel"
              v-text="$t('cancel')"
            />
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="settleHandleSubmit"
              v-text="$t('enter')"
            />
          </b-col>
        </b-row>
      </footer>
    </b-modal>

    <!-- Card Modal -->
    <b-modal
      ref="card-modal"
      size="md"
      :title="$t('select_card')"
      centered
      no-close-on-backdrop
      hide-footer
    >
      <b-form
        class="card-modal"
        @submit.stop.prevent="cardSelectionHandleSubmit"
      >
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          :label="$t('card_type')"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="12"
              class="text-left"
            >
              <span
                v-for="(item, index) in cardTypes"
                :key="index"
                class="card-display clickable"
                :class="(selectedCard.data.type !== null && selectedCard.data.type === index) ? 'selected' : ''"
                @click="selectedCard.data.type = index"
              >
                <img :src="item">
              </span>
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          :label="$t('card_value')"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="12"
              class="text-left"
            >
              <span
                v-for="(item, index) in cardValues"
                :key="index"
                class="card-display clickable"
                :disabled="selectedCard.data.type === null"
                :class="(selectedCard.data.cardResultLabel !== null && selectedCard.data.cardResultLabel === item.cardResultLabel) ? 'selected' : ''"
                @click="selectCard(item)"
              >
                <span v-text="item.label" />
              </span>
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          :label="$t('result')"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="12"
              class="text-left"
            >
              <span
                class="card-display"
              >
                <img
                  v-if="selectedCard.data.type !== null"
                  :src="cardTypes[selectedCard.data.type]"
                >
                <span
                  v-if="selectedCard.data.cardResultLabel !== null"
                  v-text="selectedCard.data.cardResultLabel"
                />
                <span v-else>?</span>
              </span>
            </b-col>
          </b-row>
        </b-form-group>
      </b-form>
      <footer
        class="modal-footer"
        style="margin: 0px -1.4rem"
      >
        <b-row style="width: 100%">
          <b-col
            cols="12"
            md="12"
            xl="12"
            style="text-align: center"
            class="mt-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              class="mr-1"
              @click="cardSelectionHandleCancel"
              v-text="$t('cancel')"
            />
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="cardSelectionHandleSubmit"
              v-text="$t('enter')"
            />
          </b-col>
        </b-row>
      </footer>
    </b-modal>

  </div>
</template>

<script>

import {
  BOverlay,
  BCard,
  BTable,
  BPagination,
  BRow,
  BCol,
  BInputGroup,
  BFormInput,
  BInputGroupPrepend,
  BFormSelect,
  BFormDatepicker,
  BFormTimepicker,
  BButton,
  BFormGroup,
  BForm,
} from 'bootstrap-vue'

import Game from '@/models/Game'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'

import { EventBus } from '../../../eventbus'

export default {
  name: 'Unsettled',
  components: {
    BOverlay,
    BCard,
    BTable,
    BPagination,
    BRow,
    BCol,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BFormSelect,
    BFormDatepicker,
    BFormTimepicker,
    BButton,
    BFormGroup,
    BForm,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      pageLength: 50,
      dir: false,
      fields: [
        {
          key: 'account',
        },
        {
          key: 'begin_time',
        },
        {
          key: 'vip_room',
        },
        {
          key: 'table_id',
        },
        {
          key: 'shoe_id',
        },
        {
          key: 'game_id',
        },
        {
          key: 'bet_type',
        },
        {
          key: 'amount',
        },
        {
          key: 'player',
          tdClass(value, key, item) { // eslint-disable-line
            return (item.card_result === 'player') ? 'winning-card' : ''
          },
        },
        {
          key: 'banker',
          tdClass(value, key, item) { // eslint-disable-line
            return (item.card_result === 'banker') ? 'winning-card' : ''
          },
        },
        {
          key: 'result',
        },
        {
          key: 'win_lose',
        },
        {
          key: 'settle_status',
        },
        {
          key: 'end_time',
        },
        {
          key: 'rolling',
        },
        {
          key: 'action',
        },
      ],
      items: [],
      loading: true,
      perPage: 10,
      totalPage: 0,
      filter: {
        page: typeof this.$route.query.page !== 'undefined' ? this.$route.query.page : 1,
        account: typeof this.$route.query.account !== 'undefined' ? this.$route.query.account : '',
        shoeId: typeof this.$route.query.shoeId !== 'undefined' ? this.$route.query.shoeId : '',
        vipRoom: typeof this.$route.query.vipRoom !== 'undefined' ? this.$route.query.vipRoom : '',
        dateFrom: typeof this.$route.query.dateFrom !== 'undefined' ? this.$route.query.dateFrom : `${(new Date().getFullYear())}-${(new Date((new Date()).getTime() - 86400000).getMonth() + 1)}-${(new Date((new Date()).getTime() - 86400000).getDate())}`,
        dateTo: typeof this.$route.query.dateTo !== 'undefined' ? this.$route.query.dateTo : `${(new Date().getFullYear())}-${(new Date((new Date()).getTime() + 86400000).getMonth() + 1)}-${(new Date((new Date()).getTime() + 86400000).getDate())}`,
        timeFrom: typeof this.$route.query.timeFrom !== 'undefined' ? this.$route.query.timeFrom : '00:00:00',
        timeTo: typeof this.$route.query.timeTo !== 'undefined' ? this.$route.query.timeTo : '23:59:59',
      },
      settleStatusOptions: [
        { value: '', text: 'All' },
        { value: 'Unsettled', text: 'Unsettled' },
        { value: 'Settled', text: 'Settled' },
      ],
      vipRoomOptions: [
        { value: '', text: 'All' },
        { value: 'Solaire', text: 'Solaire' },
        { value: 'Jupiter', text: 'Jupiter' },
      ],
      cardTypes: [],
      cardValues: [],
      settleFormData: {
        loading: false,
        userId: null,
        historyId: null,
        cards: {
          player: [],
          banker: [],
        },
        selectedCard: {},
      },
      selectedCard: {
        elem: null,
        index: null,
        data: {
          type: null,
          value: null,
          cardResultLabel: null,
        },
      },
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    async loadData(page = 1) {
      this.loading = true
      const searchParams = {}
      this.filter.page = page
      Object.keys(this.filter).forEach(e => {
        if (typeof this.filter[e] === 'number') {
          if (this.filter[e] > 0) {
            searchParams[e] = this.filter[e]
          }
        } else if (typeof this.filter[e] === 'string') {
          if (this.filter[e] !== null && this.filter[e].length > 0) {
            searchParams[e] = this.filter[e]
          }
        }
      })
      this.$router.replace({ query: searchParams })
      searchParams.auditUnsettled = true
      const response = await Game.list(searchParams)
      this.items = response.data.data
      this.totalPage = response.data.meta.pagination.total
      this.perPage = response.data.meta.pagination.per_page
      EventBus.$emit('getMyUpdatedUserData') // Todo: Need to refactor
      this.loading = false
    },
    filterByAccount(account) {
      this.filter.account = account
      this.loadData()
    },
    resetSettleFormData() {
      this.settleFormData = {
        loading: false,
        userId: null,
        historyId: null,
        cards: {
          player: [
            {
              card: null,
              value: null,
            },
            {
              card: null,
              value: null,
            },
            {
              card: null,
              value: null,
            },
          ],
          banker: [
            {
              card: null,
              value: null,
            },
            {
              card: null,
              value: null,
            },
            {
              card: null,
              value: null,
            },
          ],
        },
      }
    },
    showSettle(data) {
      this.resetSettleFormData()
      this.cardTypes = data.card_types
      this.cardValues = data.card_values
      this.settleFormData.userId = data.user_id
      this.settleFormData.historyId = data.id
      this.$refs['settle-modal'].show()
    },
    settleHandleCancel() {
      this.$refs['settle-modal'].hide()
    },
    showCardSelection(card, index) {
      this.resetCardSelectionFormData()
      this.selectedCard.elem = card
      this.selectedCard.index = index
      this.$refs['card-modal'].show()
    },
    resetCardSelectionFormData() {
      this.selectedCard = {
        elem: null,
        index: null,
        data: {
          type: null,
          value: null,
          cardResultLabel: null,
        },
      }
    },
    cardSelectionHandleCancel() {
      this.resetCardSelectionFormData()
      this.$refs['card-modal'].hide()
    },
    cardSelectionHandleSubmit() {
      let card = null
      let value = null
      if ((this.selectedCard.data.type !== null && this.selectedCard.data.type !== '') && (this.selectedCard.data.value !== null && this.selectedCard.data.value !== '')) {
        card = `${this.selectedCard.data.type}${this.selectedCard.data.cardResultLabel}`
        value = this.selectedCard.data.value
      }
      if (this.selectedCard.elem === 'player') {
        this.settleFormData.cards.player[this.selectedCard.index].card = card
        this.settleFormData.cards.player[this.selectedCard.index].value = value
      } else if (this.selectedCard.elem === 'banker') {
        this.settleFormData.cards.banker[this.selectedCard.index].card = card
        this.settleFormData.cards.banker[this.selectedCard.index].value = value
      }
      this.$refs['card-modal'].hide()
    },
    selectCard(data) {
      if (this.selectedCard.data.type !== null) {
        this.selectedCard.data.value = data.value
        this.selectedCard.data.cardResultLabel = data.cardResultLabel
      }
    },
    async settleHandleSubmit() {
      try {
        if (this.settleFormData.loading === false) {
          this.settleFormData.loading = true
          const response = await Game.settle(this.settleFormData)
          this.settleFormData.loading = false
          if (response.data.status) {
            this.$swal({
              title: 'Success!',
              text: response.data.results.message,
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            await this.loadData()
            this.$refs['settle-modal'].hide()
            this.resetSettleFormData()
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: response.data.results.message,
              },
            }, {
              position: 'top-center',
            })
          }
        }
      } catch (e) {
        this.settleFormData.loading = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: e.message,
          },
        }, {
          position: 'top-center',
        })
      }
    },
  },
}
</script>
